<!-- 隐私政策 -->
<template>
  <div class="privacy">
    <SecretPolicy />
  </div>
</template>

<script>
import SecretPolicy from "@/views/login/components/SecretPolicy";

export default {
  name: "Privacy",
  components: { SecretPolicy }
};
</script>
<style lang="scss">
.privacy {
  .agreeContent {
    padding: 0.2rem 0.18rem 0.15rem;
    font-size: 0.15rem;
    color: $mainBlack;
    background: #fff;
    text-align: justify;
    line-height: 0.24rem;
    .indent {
      text-indent: 0.3rem;
    }
  }
}
</style>
